.vistaFormulario .MuiGrid-root.MuiGrid-item.botones {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vistaFormulario.formulario {
  padding-top: 15px;
  padding-bottom: 15px;
}
.vistaFormulario.footer {
  border-top: 1px solid #c8c8c8;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
}
.vistaFormulario.footer .botones {
  display: flex;
  justify-content: center !important;
  text-align: center;
}

#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg.vistaFormulario.footer.css-1oqqzyl-MuiContainer-root > div {
  display: flex;
  justify-content: center !important;
}

#root > div > form > main.MuiContainer-root.MuiContainer-maxWidthLg.vistaFormulario.footer.css-1oqqzyl-MuiContainer-root > div {
  display: flex;
  justify-content: center !important;
}

#contenedor > form > div:nth-child(3) {
  margin: 10% 0%;
}

@media screen and (min-width: 768px) {
  .vistaFormulario.footer .botones {
    text-align: right;
  }
}
