#error, #errorLeyenda {
    color: #9b0000;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    max-width: 400px;
}

.text {
    font-size: 17px;
    font-weight: 500;
}


#error div.titulo {
    font-size: 1.5rem;
    font-weight: 700;
}
