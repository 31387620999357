@media screen and (min-width: 500px) {
  .containerReportes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.containerReportes {
  margin: 90px 0px;
}
