.SidebarLink {
  display: flex;
  color: #000000;
  align-items: center;
  padding: 0px 5px;
  width: 95%;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 1rem;
}

.SidebarLink:hover {
  background-color: #ffe600;
  /*   border-left:  solid green; */
  cursor: pointer;
  color: black;
}

#listItems {
  width: 100%;
  background-color: white;
}

.icono {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  width: 100%;
  justify-content: flex-start;
}

.DropdownLink {
  height: 60px;
  padding-left: 2.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 1rem;
}

.DropdownLink:hover {
  background-color: #ffe600;
  cursor: pointer;
  color: black;
}
