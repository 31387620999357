.css-zow5z4-MuiGrid-root {
  margin-left: 0px !important;
}

.containerLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  margin-left: 0px !important;
}

.css-1v2weco-MuiGrid-root > .MuiGrid-item {
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.containerCircle {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.imageLogoLoading {
  height: 58px;
  width: 62px;
  position: fixed;
  top: 162px;
}

.circleProgres {
  color: #ffe600;
  height: 80px;
  width: 80px;
  position: fixed;
  top: 150px;
}
