.custom-table tr:not(:only-child) td:not(:only-child) {
  border: 1px solid black;
}

.custom-table tr:only-child,
.custom-table td:only-child {
  border: 1px solid black;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  display: flex;
}

.informeTabla {
  padding: 10px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin: 0;
  font-size: 1rem;
}

.informeSoloMobile {
  display: none;
}

@media only screen and (max-width: 899px) {
  .informeTabla {
    padding: 10px 10px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    margin: 0;
    font-size: 0.8rem;
  }

  .informeSoloMobile {
    display: block;
  }
}
