.container-desplegable {
  display: flex;
  margin-top: 5px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.container-icons {
  display: flex;
  width: 100%;
  height: 60px;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  background-color: #ffe600;
  opacity: 0.8;
  border-radius: 20px;
}

.text-desplegable {
  cursor: pointer;
  margin: 0 0 0 0;
  color: color;
  width: 70%;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .container-desplegable {
    display: flex;
    /*  width: 100%; */
    height: 100%;
    /*     max-width: 400px; */
    justify-content: center;
    align-items: center;
  }
}
