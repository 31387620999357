.HeadListEspacio {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

Input {
  width: 80%;
}

.container-IconLocaiton {
  width: 18%;
}

.container-Input {
  width: 82%;
}

.text-ubicacionespacio {
  font-size: 11px;
  font-weight: bold;
  margin: 0;
}

.container-IconLocation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 500) {
  .HeadListEspacio {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: space-around !important;
    align-items: center;
  }
}
