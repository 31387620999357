.login-component {
  display: flex;
  padding: 0px 0px;
  text-align: center;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #ccc;
  margin-top: 100px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  align-items: center;
  height: 500px;
  justify-content: center;
  flex-direction: column;
  width: 80%;
}

.container-login {
  display: flex;
  justify-content: center;
  align-content: center;
}

.form-login {
  width: 92%;
}

.text-welcome {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 25px;
}
