header.MuiPaper-root {
  background-color: #ffe600;
  /*   position: fixed; */
  top: 0;
  z-index: 99;
  margin-bottom: 10px;
}
.MuiTypography-root img {
  padding-top: 3px;
  padding-bottom: 3px;
  height: 65px;
}
.MuiBox-root a {
  text-decoration: none;
}
.MuiBox-root .MuiButton-root {
  font-weight: 600;
  text-transform: uppercase;
  padding: 20px 10px;
  margin: 0;
  color: #000;
}
.MuiBox-root .MuiButton-root:hover {
  background: rgba(0, 0, 0, 0.1);
}
.MuiAvatar-circular.MuiAvatar-circular.MuiAvatar-colorDefault {
  background-color: #808080;
}
#menu-appbar .MuiMenu-list a {
  text-decoration: none;
  color: #000;
}
