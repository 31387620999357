.containerCrearPoligonos{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formCreatePoligonos{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
}