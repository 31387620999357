.containerPerfil{
    display: flex;
    /* width: 100%; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width:500px) {
    .perfilInputs{
        width: 50%;
        max-width: 500px;
    }
}