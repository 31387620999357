.container-areas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.listadoAreas {
  width: 95%;
  margin: 22px 5px;
}
