.DisplayInfoUl {
    display: flex;
    text-align: left;
    letter-spacing: 0.02rem;
    flex-direction: column;
    align-items: center;
    width: "90%";
}
.DisplayInfoUl .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.DisplayInfoUl .row .titulo {
    padding: 0;
    margin: 20px 5px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.06rem;
    text-align: center;
}
.DisplayInfoUl .row .MuiButtonBase-root {
    margin-top: 10px;
}
.DisplayInfoUl .row .MuiSvgIcon-root {
    width: 1.2rem;
    height: 1.2rem;
}
.lista{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center
}
.DisplayInfoUl li {
    list-style: none;
    border: 1px solid rgba(0,0,0,.125);
    border-bottom: none;
    padding: 10px;
    font-size: 0.9rem;
    background-color: #fff;
}
.DisplayInfoUl li span {
    color: rgb(100, 100, 100);
}
.DisplayInfoUl li:first-child{
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.DisplayInfoUl li:last-child{
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}