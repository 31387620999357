@import "../fonts/Roboto.ttf";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

.fondoLogo{
  position:fixed;
  left: -35px;
  top: -35px;
  width: 100%;
  height: 100%;
  
  z-index: -1;
}

h1, h2, h3, h4, h5, h6, p, span, a, button, input, textarea, select{
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: 'Roboto', sans-serif;
}

.paper{
  text-align: center;
  padding: 10px 15px;
}
.error{
  color:rgb(158, 7, 7);
}

.MuiButton-root.MuiButton-contained,
.MuiButton-root.MuiButton-outlined{
  font-family: 'Roboto', sans-serif;
}
