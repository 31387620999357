@media (max-width: 777px) {
  .containerBtnInspecciones {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 778px) {
  .containerBtnInspecciones {
    display: flex;
    width: 25%;
    flex-direction: row !important;
    justify-content: flex-end;
  }
}
