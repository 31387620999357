.print-first-page {
  display: none;
}

.sectionTitle {
  text-align: left;
  text-decoration: underline;
}

#pdf {
  width: 70%;
  max-width: 800px;
  margin: auto;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 10mm;
  }

  #pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
  }

  .pageBreak:last-child {
    page-break-after: auto;
  }

  body {
    display: flex;
    background-color: white !important;
    flex-direction: column;
    align-items: center;
    font-size: 14;
  }

  .pageBreak {
    width: 95%;
    clear: both;
    page-break-after: always;
    margin-left: auto;
    margin-right: auto;
  }

  tr {
    page-break-inside: avoid !important;
  }

  .print-first-page {
    position: relative;
    text-align: center;
    color: white;
    display: block;
    clear: both;
    page-break-after: always;
  }
  .btnSubmit {
    display: none !important;
  }
}
