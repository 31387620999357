.containerInformes {
  flex-wrap: wrap;
  display: flex;
  /*   justify-content: center; */
}

.filters {
  background-color: #888484 !important;
  z-index: 1;
}

.boxType {
  border: 2px solid #c1bdbd;
  transition: border-color 0.1s ease-in;
  display: flex;
  width: 95%;
  padding: 10px 4px;
  border-color: #c1bdbd;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
}

/* .boxType:hover {
  display: flex;
  border: 2px solid #4c8bf7;
  width: 95%;
  padding: 10px;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
} */

@media screen and (min-width: 1000px) {
  .filters {
    position: absolute !important;
    width: 25% !important;
    display: flex;
    z-index: 1;

    flex-direction: column !important;
    background-color: #f4f4f4 !important;

    height: 100%;
    justify-content: flex-start !important;
  }
}

@media screen and (min-width: 700px) {
  .filters {
    position: absolute !important;
    width: 100% !important;
    display: flex;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .containerFilter {
    width: 30%;
    max-width: 260px;
  }
}

@media screen and (max-width: 699px) {
  .filters {
    position: absolute !important;
    width: 100% !important;
    display: flex;
    background-color: #f4f4f4;
    flex-direction: column !important;
    flex-wrap: wrap;
  }
  .containerFilter {
    width: 100%;
  }
  .listInformes {
    display: flex;
    flex-direction: column;
  }
}
