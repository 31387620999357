#routesContainer {
  margin: 0;
  padding: 0;
  height: calc(100vh - 100px);
  margin-left: 0;
  /*   margin-top: 70px; */
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.3s;
}

#routesContainer.extendido {
  margin-left: 0;
}

#routesContainer #contenedor {
  margin: 17px 10px 50px 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  border-radius: 5px;
}
