.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed #ccc;
  padding: 25px 15px;
  text-align: center;
  letter-spacing: 0.02rem;
  height: 7rem;
}
