.btnFooterInforme {
  border: 1px solid transparent;
  background-color: #cccccc;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
}

.selected {
  background-color: white;
}

.future {
  background: #ff0c0c;
  opacity: 0.6;
  color: white;
  height: 100%;
  cursor: not-allowed;
}

.current {
  background-color: #feea32;
  opacity: 1;
  color: black;
}
