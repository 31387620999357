/* @import "../fonts/OpenSans.ttf" */

.MuiFormLabel-root-MuiInputLabel-root,
.MuiSelect-select.MuiOutlinedInput-input {
  font-family: "Open Sans", sans-serif;
  text-align: left;
}

.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: #eaeaea;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: rgba(211, 211, 211, 0);
}

.MuiFormHelperText-root.MuiFormHelperText-sizeMedium {
  font-family: "Open Sans", sans-serif;
}

.MuiInputLabel-root.MuiInputLabel-outlined {
  font-family: "Open Sans", sans-serif;
  color: #545454;
}

.css-111avd1-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: #eaeaea;
}

#menu-
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 500px;
  min-height: 50px;
}
