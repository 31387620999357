.containerRelevamiento {
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerDates {
  display: "flex" !important ;
  width: "100%";
  flex-direction: "row";
  background-color: beige;
}
.container {
  display: "flex";
  flex-direction: "row";
  flex-wrap: nowrap;
  margin-bottom: "10%";
  height: "66%";
  width: 100%;
}

.success {
  display: "flex";
  justify-content: "center";
}

.containerBtnCreateRelevamiento {
  margin: "20px 0 25px 0";
}
