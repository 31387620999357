.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-left: 0 !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  padding-left: 0 !important;
}

.containerPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
